import React from 'react';
import router from 'next/router';
import { GetStaticProps } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { Stack, Typography } from '@mui/material';
import AnnouncementOutlinedIcon from '@mui/icons-material/AnnouncementOutlined';
import FormBox from '../components/FormBox';
import payStyles from '../components/Home/PayAccept/PayAccept.style';
import { useTranslation } from 'next-i18next';

type Props = {
	// Add custom props here
};
export const getStaticProps: GetStaticProps<Props> = async ({ locale }) => ({
	props: {
		...(await serverSideTranslations(locale ?? 'en', ['header', 'navigation', 'landing', 'home', 'payment'])),
	},
});
export default function Custom404() {
	const { t } = useTranslation(['payment', 'home', 'header', 'navigation']);

	return (
		<Stack alignItems="center" padding={1} spacing={1}>
			<FormBox>
				<Stack textAlign={'center'}>
					<AnnouncementOutlinedIcon sx={payStyles.iconError} />
					<Typography variant={'h1'} fontSize={'1.5rem'} marginTop={2} marginBottom={3}>
						404 - Page Not Found
					</Typography>
					<Typography variant={'h2'} marginTop={2} marginBottom={3}>
						There is nothing here
					</Typography>
					<Typography
						sx={payStyles.back}
						textTransform={'uppercase'}
						marginTop={4}
						onClick={() => router.push({ pathname: '/' })}
					>
						{t('BACK_HOME', { ns: 'home' })}
					</Typography>
				</Stack>
			</FormBox>
		</Stack>
	);
}
